import React, {useState, useEffect}from 'react';
import axios from 'axios';
import dataFooterMenu from '../../data/footer/footerMenu';

const FooterMenu = () => {

    const [aboutUs, setAboutUs] = useState([]);

    useEffect(() => {
		const fetch = async () => {
			await axios.get("api/AboutUs")
				.then((resp) => {
					if (resp.status == 200) {
						setAboutUs(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

    return (
        <nav className="menu-secondary">
            <ul className="nav">
                <li className="nav-item">
                    <a title={dataFooterMenu[0].title} href={dataFooterMenu[0].link + aboutUs.facebook}>
                        <i className={dataFooterMenu[0].icon}></i>
                    </a>
                </li>
                {/* <li className="nav-item">
                    <a title={dataFooterMenu[1].title} href={dataFooterMenu[1].link}>
                        <i className={dataFooterMenu[1].icon}></i>
                    </a>
                </li> */}
                <li className="nav-item">
                    <a title={dataFooterMenu[2].title} href={dataFooterMenu[2].link + aboutUs.instagram}>
                        <i className={dataFooterMenu[2].icon}></i>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default FooterMenu;
