import React from 'react';
// import logo from '../../assets/img/logo/1.png'

const HeaderLogo = ( { logoColor } ) => {
    console.log(logoColor)
    return (
        <div className="header-logo">
            <a className="logo logo-secondary transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                {/* <img src={require('../../assets/img/logo/logo-'+logoColor+'.png')} alt="Logo" /> */}
                {/* <img src={ "assets/img/logo/logo_custom_" + logoColor + ".png" } alt="Logo" /> */}
                <img className="animated zoomin"src={require('../../assets/img/logo/logo_custom_' + logoColor + '.png')} alt="Logo" />
            </a>
        </div>
    );
};

export default HeaderLogo;
