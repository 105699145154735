import React from 'react';
import { useTranslation } from 'react-i18next';

const Actions = () => {

    const {t} = useTranslation();

    return (
        <div className="actions">
            <div className="actions-items">
                <a className="actions-item" href={ process.env.PUBLIC_URL + "/service-booking" }>
                    <span>{t("actions.actionsItem")}</span>
                </a>
            </div>
        </div>
    );
};

export default Actions;
