import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutOurTeam = () => {

    const {t} = useTranslation();

    return (
        <div id="our-team" className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr">{t("aboutOurTeam.title")}</h2>
            </div>

            <div className="row gutter-width-sm with-pb-md">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/demo/18_img.jpg" alt="Rosie Ford" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>{t("aboutOurTeam.teamMember1")}</p>
                            </div>

                            <h4 className="team-member-t-head">Rosie Ford</h4>

                            <div className="team-member-description">
                                <p>{t("aboutOurTeam.teamMemberDescription1")}</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/adveits/"><i className="fab fa-facebook-f"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="LinkedIn" href="https://www.linkedin.com/company/18135051/"><i className="fab fa-linkedin-in"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/adveits/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/demo/19_img.jpg" alt="Tom Henders" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>{t("aboutOurTeam.teamMember2")}</p>
                            </div>

                            <h4 className="team-member-t-head">Tom Henders</h4>

                            <div className="team-member-description">
                                <p>{t("aboutOurTeam.teamMemberDescription2")}</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/adveits/"><i className="fab fa-facebook-f"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="LinkedIn" href="https://www.linkedin.com/company/18135051/"><i className="fab fa-linkedin-in"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/adveits/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/demo/20_img.jpg" alt="Lana Hemb" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>{t("aboutOurTeam.teamMember3")}</p>
                            </div>

                            <h4 className="team-member-t-head">Lana Hemb</h4>

                            <div className="team-member-description">
                                <p>{t("aboutOurTeam.teamMemberDescription3")}</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/adveits/"><i className="fab fa-facebook-f"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="LinkedIn" href="https://www.linkedin.com/company/18135051/"><i className="fab fa-linkedin-in"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/adveits/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutOurTeam;
