import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const ContactsUs = () => {
    const { t } = useTranslation();
    return (
        <section id="contact-us" className="block">
            <div className="bg-pink-light spacer p-top-xl p-bottom-xl">
                <div className="wrapper text-center">
                    <div className="title">
                        <h2>{t('contactsUs.youCanContactUs')}</h2>
                    </div>

                    <a href={ process.env.PUBLIC_URL + "/contacts" } className="btn btn-outline-secondary">{t('contactsUs.contact')}</a>
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
