import React, { Component } from 'react';
import SwipeBox from 'fslightbox-react';
import MoreExamples from '../../blocks/ui/blocks/MoreExamples';
import { PrismCode } from '../prsimjs/Prismjs';

class OpenPopup extends Component {
    state     = {
        isVisible: false,
        slide: 0,
    };
    showSlide = ( slide, event ) => {
        event.preventDefault();
        this.setState( {
            isVisible: ! this.state.isVisible,
            slide: slide
        } );
    };

    render() {
        return (
            <div id="pop-up" className="spacer m-top-xl">
                

                <div id="swipebox">
                    
                    <div className="bd-example bd-example-images">
                        <a onClick={ ( event ) => this.showSlide( 1, event ) } className="btn btn-primary swipebox" href="#example-image">Open blank image</a>
                    </div>
                    <div style={{display:this.state.isVisible?"block":"none"}} className="form-group form-group-lg">
                    <input name="phone"
                                   type="text"
                                   id="cf-1-phone"
                                   placeholder="Your phone here"
                                   required="required"/>
                    </div>
                    {

                      /*  
                    <SwipeBox 
                        toggler={ this.state.isVisible }
                        slide={ this.state.slide }

                        urls={ [
                            'assets/img/ui/300x300.jpg',
                        ] }
                    />*/
                    
    }
    
                    
                </div>

            </div>
        );
    }
}

export default OpenPopup;
