import React from 'react';

const NewsTitle = () => {
    return (
        <div className="title">
            <h2>Best Cosmetics for Your Body and Soul</h2>
        </div>
    );
};

export default NewsTitle;
