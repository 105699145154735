import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from 'i18next-localstorage-cache';


i18n.use(Cache).use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'en',
    whitelist: ['en', 'ee', 'ua', 'ru'],
    debug: true,
    detection: {
        order: ['cookie', 'localStorage', 'navigator'],
        caches: ['localStorage', 'cookie']
    },
    cache: {
        enabled: true,
        expirationTime: 7,
        version: '1.5',
        types: ['localStorage', 'cookie'],
        keySeparator: '.',
        checkOnLoad: true,
        reloadOnRestart: false
      },
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
