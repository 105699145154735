import React, { Component, Fragment } from 'react';
import SwipeBox from 'fslightbox-react';
import axios from 'axios';

class SwipeBoxComponent extends Component {
    state = {
        isVisible: false,
        slide: 0,
        AllImages: []
    };
    showSlide = ( slide, event ) => {
        event.preventDefault();
        if (slide >= 0 && slide <= this.state.AllImages.length) {
            this.setState( {
                isVisible: !this.state.isVisible,
                slide: slide
            } );   
        }
    };

    async componentDidMount(){
        await axios.get("api/Cloudinary/CloudinaryGetAllFiles",
		).then((resp) => {
        //     for (let i = 0; i < resp.data.length; i++) {
        //     let img = new Image()
        //     img.src = resp.data[i];
        //     img.onload = () => {
        //         if(img.width >= 1000 || img.height >= 1000)
        //         {
        //             resp.data = resp.data.filter((SRC) => SRC !== resp.data[i]);
        //         }
        //     };	
        // }
        this.setState({ AllImages: resp.data });
		}).catch((err) => {
			console.log("error", err);
			alert("Something went wrong")
		})
    }
    render() {
        return (
            <Fragment>
                <SwipeBox
                    key={this.state.AllImages}
                    toggler={ this.state.isVisible }
                    slide={ this.state.slide }
                    urls={this.state.AllImages}
                    disableLocalStorage={true}
                />

                {this.state.AllImages.map((image, index) => (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6" key={image}>
                        <a onClick={(event) => this.showSlide(index+1, event)} ref={this.swipeImg} className="gallery-item swipebox" href="assets/img/placeholder/780x500.jpg" rel="gallery">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src={image} alt="Face & Body" />
                                </div>
                            </div>

                            <div className="img-bg-color"></div>
                        </a>
                    </div>
                ))}
            </Fragment>
        );
    }
}

export default SwipeBoxComponent;
