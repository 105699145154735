import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutServices = () => {

    const {t} = useTranslation();

    return (
        <div id="services" className="block spacer p-top-xl">
            <div className="text-center">
                <a href={ process.env.PUBLIC_URL + "/services" } className="btn btn-outline-secondary">
                    {t("aboutServices.btnAboutServices")}
                </a>
            </div>
        </div>
    );
};

export default AboutServices;
