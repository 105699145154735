import React from 'react';
import { useTranslation } from 'react-i18next';

const MoreExamples = ( { actionLink, actionLinkContent, contentAfter, contentBefore } ) => {

    const {t} = useTranslation();

    return (
        <div className="bd-callout bd-callout-warning">
            <h5>{t("moreExamples.moreExamples")}</h5>

            <p>{ contentBefore }<a href={ actionLink } target="_blank" rel="noopener noreferrer">{ actionLinkContent }</a>{ contentAfter }</p>
        </div>
    );
};

export default MoreExamples;
