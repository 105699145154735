import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalMenuPrimary = () => {

    const {t} = useTranslation();

    return (
        <nav className="menu-primary">
            <ul className="nav">
                <li className={ "nav-item" + (window.location.pathname === '/' ? " current-nav-item" : "") }>
                    <a title="Home" href={ process.env.PUBLIC_URL + "/" }>{t("modalMenuPrimary.home")}</a>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/services' ? " current-nav-item" : "") }>
                    <a title="Services" href={ process.env.PUBLIC_URL + "/services" }>{t("modalMenuPrimary.services")}</a>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/gallery' ? " current-nav-item" : "") }>
                    <a title="Gallery" href={ process.env.PUBLIC_URL + "/gallery" }>{t("modalMenuPrimary.gallery")}</a>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/about' ? " current-nav-item" : "") }>
                    <a title="About us" href={ process.env.PUBLIC_URL + "/" }>{t("modalMenuPrimary.aboutUs")}</a>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/contacts' ? " current-nav-item" : "") }>
                    <a title="Contacts" href={ process.env.PUBLIC_URL + "/contacts" }>{t("modalMenuPrimary.contacts")}</a>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/service-booking' ? " current-nav-item" : "") }>
                    <a title="Booking" href={ process.env.PUBLIC_URL + "/service-booking" }>{t("modalMenuPrimary.booking")}</a>
                </li>
                {/* <li className={ "nav-item" + (window.location.pathname === '/booking' ? " current-nav-item" : "") }>
                    <a title="Booking" href={ process.env.PUBLIC_URL + "/booking" }>{t("modalMenuPrimary.booking")}</a>
                </li>
                <li className={ "nav-item" + (window.location.pathname === '/currency' ? " current-nav-item" : "") }>
                    <a title="currency" href={ process.env.PUBLIC_URL + "/currency" }>{t("modalMenuPrimary.currency")}</a>
                </li>
                <li className={ "nav-item" + (window.location.pathname === '/currency' ? " current-nav-item" : "") }>
                    <a title="currency" href={ process.env.PUBLIC_URL + "/currency" }>{t("modalMenuPrimary.currency")}</a>
                </li> */}
            </ul>
        </nav>
    );
};

export default ModalMenuPrimary;
