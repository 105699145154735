import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from 'axios'

const ServicesContent = () => {
	const [services, setServices] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [serviceCategories, setServiceCategories] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			await axios.get("api/Services")
				.then((resp) => {
					if (resp.status == 200) {
						setServices(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			await axios.get("api/Currencies")
				.then((resp) => {
					if (resp.status == 200) {
						setCurrencies(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			await axios.get("api/Languages")
				.then((resp) => {
					if (resp.status == 200) {
						setLanguages(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

	useEffect(() => {
		const fetch = async () => {
			await axios.get("api/ServiceCategories")
				.then((resp) => {
					if (resp.status == 200) {
						setServiceCategories(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);






	function getTime(minTime) {
		let rhoursTime = Math.floor(minTime / 60);
		let rminutesTime = Math.round((minTime / 60 - rhoursTime) * 60);
		let convertTime = rhoursTime + t('servicesContent.h') + ":" + rminutesTime + t('servicesContent.m');
		if (rminutesTime == 0) {
			convertTime = rhoursTime + t('servicesContent.h');
		}
		if (rhoursTime == 0) {
			convertTime = rminutesTime + t('servicesContent.m');
		}
		return convertTime;
	}

	const { t } = useTranslation();


	return (
		<div id="page-content" className="spacer p-top-xl">
			<div className="wrapper">
				<div className="content">
					<div id="single">
						<div id="single-content">
							<div id="price-list" className="block spacer p-top-xl">

								<div className="title">
									<h2 className="hr">{t('servicesContent.priceList')}</h2>
								</div>

								<div className="price-list">
									<div className="price-list-items">
										{serviceCategories.map((category) => (
											<div className="price-list-item">
												<div>
													<div className="price-list-item-title">
														<h3>{category.resources[category.resources.findIndex((cat) => cat.idLanguage === languages[languages.findIndex((lang) => lang.shortName === i18next.language.substring(0,2))]?.id ?? 0)]?.text ?? "en"}</h3>
													</div>
													<ul className="price-list-item-list-group list-group">
														{services.map((service) => (
															service.serviceCategoryId === category.id ?
																<li className="list-group-item d-flex justify-content-between align-items-center" key={service.id}>
																	<span className="list-group-title" scope='row'>
																		{service.resources[service.resources.findIndex((res) => res.idLanguage === languages[languages.findIndex((lang) => lang.shortName === i18next.language.substring(0,2))]?.id ?? 0)]?.text ?? "en"}
																		{" (" + getTime(service.duration) + ")"}
																	</span>
																	<th scope='row'>
																		{t('servicesContent.from')}
																		{service.price}
																		{currencies[currencies.findIndex((e) => e.id === service.currency)].symbol}
																	</th>
																</li>
																: null
														))}
													</ul>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>



















		// <div className="wrapper">
		//     <div className="content">
		//         <div className="row gutter-width-sm with-pb-sm services-items">
		//             <table className='table table-modern table-hover'>
		//                 <thead>
		//                     <tr>
		//                         <th scope='col'>{t('servicesContent.service')}</th>
		//                         <th scope='col'>{t('servicesContent.price')}</th>
		//                         <th scope='col'>{t('servicesContent.duration')}</th>
		//                     </tr>
		//                 </thead>
		//                 <tbody>
		//                     {services.map((service) => (
		//                         <tr key={service.id}>
		//                             <th scope='row'>
		//                                 {service.resources[service.resources.findIndex((res) => res.idLanguage === languages[languages.findIndex((lang) => lang.shortName === i18next.language)]?.id ?? 0)]?.text ?? "en"}
		//                             </th>
		//                             <th scope='row'>
		//                                 {t('servicesContent.from')}
		//                                 {service.price}
		//                                 {currencies[currencies.findIndex((e) => e.id === service.currency)].symbol}
		//                             </th>
		//                             <th scope='row'>
		//                                 {getTime(service.duration)}
		//                             </th>
		//                         </tr>
		//                     ))}
		//                 </tbody>
		//             </table>

		//         </div>
		//     </div>
		// </div>
	);
};

export default ServicesContent;