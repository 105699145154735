import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import ModalMenuLanguages from '../menu/ModalMenuLanguages';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from 'axios'

const PageTitleHome = () => {

    const [resources, setResources] = useState([]);
    const [languages, setLanguages] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			await axios.get("api/Resources/company-name")
				.then((resp) => {
					if (resp.status == 200) {
						setResources(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

    useEffect(() => {
		const fetch = async () => {
			await axios.get("api/Languages")	
				.then((resp) => {
					if (resp.status == 200) {
						setLanguages(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

    const {t} = useTranslation();
    
    return (
        <section id="page-title" className="block with-img" style={ { backgroundImage: 'url(../../assets/img/demo/01_img.jpg)' } }>
            <div className="wrapper text-center d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                        <h1 className="large">{resources[resources.findIndex((res) => res.idLanguage === languages[languages.findIndex((lang) => lang.shortName === i18next.language)]?.id ?? 0)]?.text ?? ""}</h1>
                    </div>

                    <div className="description spacer p-top-lg">
                        <p>{resources[resources.findIndex((res) => res.idLanguage === languages[languages.findIndex((lang) => lang.shortName === i18next.language)]?.id ?? 0)]?.description ?? ""}</p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <Link href="#contact-us"
                              to="contact-us"
                              spy={ true }
                              smooth={ true }
                              duration={ 0 }
                              className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id">
                            {t("pageTitleHome.scroll")}
                        </Link>
                    </div>

                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
