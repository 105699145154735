import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutsUs = () => {

    const {t} = useTranslation();

    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2>{t("aboutsUs.title")}</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>{t("aboutsUs.description")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
