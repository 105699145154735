import React, { Component } from 'react';
//import moment from 'moment';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input/input';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import { object } from 'prop-types';
import Select from 'react-select'

const customStyles = {
	option: (provided, state) => ({
		...provided,
		color: 'white',          // Устанавливаем белый цвет текста
		backgroundColor: '#6c757d',  // Устанавливаем фоновый цвет на #6c757d
	}),
  };

class BookingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedServices: undefined,
            values: {
                startTime:'', 
                endTime:'', 
                curDate:'',
                smsMessage: '',
                smsPhone: '',
                smsCode: '',
                userSmsCode: '',
                customerId:0,
                smsBlockVisible:false,
                name: '',
                phone: this.SetPhoneNum(),
                email: '',
                message: '',
                date: '',
                selectedServiceId: 0,
                selectedTime: '',
                freeTime: [],
                customers: [],
                loadingFreeTime:false,
                services: [{
                    id: 0,
                    resourceName: "",
                    Price: 0,
                    currency: "",
                    duration: 0,
                    resources: [{
                        id: 0,
                        idLanguage: 0,
                        resourceName: "",
                        text: "",
                        description: ""
                    }, {
                        id: 0,
                        idLanguage: 0,
                        resourceName: "",
                        text: "",
                        description: ""
                    }, {
                        id: 0,
                        idLanguage: 0,
                        resourceName: "",
                        text: "",
                        description: ""
                    }, {
                        id: 0,
                        idLanguage: 0,
                        resourceName: "",
                        text: "",
                        description: ""
                    },]
                }]
            },
            successMessage: "Sender's message was sent successfully",
            warningMessage: 'Fill up the form, please!',
            errorMessage: 'Something go wrong. Try again later!',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000,
            languages: []
        };
        this.getServices();
        this.getLang();
        this.getCustomers();
    }

    
    async getLang() {
        const fetch = async () => {
            await axios.get("api/Languages")
                .then((resp) => {
                    if (resp.status == 200) {
                        this.state.languages = resp.data;
                    }
                }).catch((err) => {
                    console.log("error", err);
                    alert("error");
                })
        };
        fetch();
    };

    async getServices() {
        await axios.get("api/Services")
            .then((resp) => {
                if (resp.status == 200) {
                    this.setState({
                        values: {
                            ...this.state.values,
                            ['services']: resp.data
                        }
                    })
                }

            }).catch((err) => {
                console.log("error", err);
                alert("error");
            })
    };

    async getCustomers() {
        await axios.get("api/Customers")
        .then((resp) => {
            if (resp.status == 200) {
                this.setState({
                    values: {
                        ...this.state.values,
                        ['customers']: resp.data
                    }
                })
            }
        }).catch((err) => {
            console.log("error", err);
            alert("error");
        })
    }
    async sendSMS() {
        
        const sms_data = {
            Phone: this.state.values.phone,
            Text: this.state.values.smsMessage,
          };
        
         await axios.post( '/api/SMS', sms_data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }, ).then( response => {
            console.log(response)
                //this.callAlert( this.state.responseMessage, response.data.status )
            } ).catch( error => {
                console.log(error)
                this.callAlert( this.state.errorMessage, 'error' )
            } );
    }
    async sendSMSCode() {
      let code = Math.floor(100000 + Math.random() * 900000);
       await this.setState({ values: {
            ...this.state.values, 
            ['smsBlockVisible']: true,
            ['smsCode']: code
           } });
        const sms_code_data = {
            Phone: this.state.values.phone,
            Text: "Please use this code "+this.state.values.smsCode,
          };
        
         await axios.post( '/api/SMS', sms_code_data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }, ).then( response => {
            console.log(response)
               
                //this.callAlert( this.state.responseMessage, response.data.status )
            } ).catch( error => {
                console.log(error)
                this.callAlert( this.state.errorMessage, 'error' )
            } );
    }
    sendFormData = async()=>{
        axios.post("api/ServiceBooking/client", {
            dateTimeFrom: this.state.values.startTime,
            dateTimeTo: this.state.values.endTime,
            currentDate:this.state.values.curDate,
            comments: this.state.values.message == undefined ? "" : this.state.values.message,
            clientId: this.state.values.customerId,
            serviceIds: this.state.values.selectedServiceId,
            name: this.state.values.name == undefined ? null : this.state.values.name,
            email: this.state.values.email == undefined ? null : this.state.values.email,
            phone: this.state.values.phone
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        }
        ).then((resp) => {
            if (resp.status == 200) {
               this.sendSMS();
                this.setState({ values: {
                    ...this.state.values, 
                    ['smsBlockVisible']: false,
                    ['smsCode']: '',
                    ['smsUserCode']: '',
                    ['phone']: '',
                    ['email']: '',
                    ['name']: '',
                    ['selectedServiceId']: 0,
                    ['message']: '',
                    ['selectedTime']: '',
                    ['freeTime']: [],
                    ['date']: '',
                   } });
               this.getServices();
               this.getCustomers();
                this.getServices();
                this.getCustomers();
                console.log("AAAAAA", this.state.values)
            }
        }).catch((err) => {
            console.log("error", err);
            alert("error")
        })
    }
    submitForm = async e => {
        console.log("loh");
        this.getCustomers();
        e.preventDefault();
        if (document.querySelector('#alert')) {
            document.querySelector('#alert').remove();
        }
        const { t } = this.props;
        var isValidValues = true;

        if (!this.isValidEmail(this.state.values.email)) {
            this.state.warningMessage = t('bookingForm.invalidEmailFormat');
            isValidValues = false;
            if (this.state.values.email === '') {
                isValidValues = true;
            }
        }

        if (!isValidPhoneNumber(this.state.values.phone)) {
            this.state.warningMessage = t('bookingForm.invalidPhoneFormat');
            isValidValues = false;
        }

        
        if (this.state.values.name === '') {
            this.state.warningMessage = t('bookingForm.enterYourName');
            isValidValues = false;
        }
        
        if (this.state.values.phone === '' || this.state.values.phone === undefined) {
            this.state.warningMessage = t('bookingForm.enterYourPhone');
            isValidValues = false;
        }
        
        
        //=-------------------------------------------------------

        const isMatch = (array, phone) => {
            return array.some(item => item.phone === phone);
        };

        if(isValidPhoneNumber(this.state.values.phone) === true)
        {
            var array = [];
            const fetch = async () => {
                await axios.get("/api/BlackList")
                    .then(async (resp) => {
                        if (resp.status == 200) {
                            array = resp.data;
                            
                            if(isMatch(array, this.state.values.phone) === true) {
                                await this.setState({warningMessage : t('This number is blocked')});
                                isValidValues = false;
                            }

                        }
                    }).catch((err) => {
                        console.log("error", err);
                        alert("error");
                    })
            };
            await fetch();
        }
        //=-------------------------------------------------------


        if (this.state.values.selectedTime === '' || this.state.values.selectedTime === undefined) {
            // console.log("selectedTime ", this.state.values.selectedTime);
            this.state.warningMessage = t('Select time of booking');
            isValidValues = false;
        }

        if (isValidValues) {
            
            //
            
            let customerId = this.state.values.customers.find((cl) => cl.phone == this.state.values.phone); //Search id of customer that selected Admin

            let startTime = this.state.values.selectedTime.split("!")[0]
            let endTime = this.state.values.selectedTime.split("!")[1]

            let curDate =  this.state.values.date
            await this.setState({ values: {
                        ...this.state.values, 
                        ['startTime']: startTime,
                        ['endTime']: endTime,
                        ['curDate']: curDate,
                        ['smsPhone']: this.state.values.phone,
                        ['customerId']: customerId == undefined ? 0 : parseInt(customerId.id.toString()),
                        ['smsMessage']: 'Hello, thank you for reserving time '
                        +startTime+'-'+endTime+' on date '+curDate+' in Beauty NK salon!'
                       } });
                       this.sendSMSCode()
           
            
        } else {
            this.callAlert(this.state.warningMessage, 'error');
        }
    };

    isValidEmail(email) {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

            ;
    };


    handleInputChange = e =>
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        });

    handleInputChangePhone = (e) => {
        if (e !== undefined) {
            this.setState({
                values: {
                    ...this.state.values,
                    ['phone']: e
                }
            })
        } else {
            this.setState({
                values: {
                    ...this.state.values,
                    ['phone']: ''
                }
            })
        }
    };

    handleInputChangeService = (e) => {
        if (e !== undefined) {
            this.state.values.selectedServiceId = e;
            this.setState({
                values: {
                    ...this.state.values,
                    ['selectedServiceId']: e
                }
            })
        } else {
            this.setState({
                values: {
                    ...this.state.values,
                    ['selectedServiceId']: ''
                }
            })
        }
    };

    handleInputChangeDate = (e) => {
        if (e !== undefined) {
            // console.log("e ", e)
            this.state.values.date = e;
            // this.setState({
            //     values: {
            //         ...this.state.values,
            //         ['date']: e
            //     }
            // })
        } else {
            this.setState({
                values: {
                    ...this.state.values,
                    ['date']: ''
                }
            })
        }
    };
    checkSmsCode = async (e) => {
        if(this.state.values.smsCode==this.state.values.userSmsCode){
            this.state.successMessage = this.props.t('bookingForm.successfullyBooked');
            this.callAlert(this.state.successMessage, 'success');
            this.setState({ isSubmitting: true });
           this.sendFormData();
        }
        else
        {
            await this.setState({
                warningMessage:this.props.t('bookingForm.incorrectSMSCode')
            });
            this.callAlert(this.state.warningMessage, 'error');
        }
    }
    checkFreeTime() {
        // console.log("this.state.values.selectedServiceId ", this.state.values.selectedServiceId);
        // console.log("this.state.values.date ", this.state.values.date);
        // console.log("this.state.selectedServices ", this.state.selectedServices);

        // const serviceIds = this.state.selectedServices.map(service => service.value);
        //const serviceIds = Object.values(selectedServices).map(service => service.value);
        //this.state.values.date !== "" && 
        if (this.state.values.date !== "" && this.state.values.selectedServiceId !== 0) {
            const fetch = async () => {
               await this.setState({
                    values: {
                        ...this.state.values,
                        ['freeTime']: [],
                        ['loadingFreeTime']: true
                    }
                })
               await axios.post("api/GetMultiFreeTimeToBooking", {
                    dateTimeFrom: this.state.values.date,
                    serviceIds: this.state.values.selectedServiceId
                }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
                ).then((resp) => {
                    if (resp.status == 200) {
                        // console.log("resp.data ", resp.data);
                        let items = resp.data.map((response) => {
                            return {
                                startTime: response.startTime.substr(0,19),
                                endTime: response.endTime.substr(0,19)
                            };
                        });
                        // console.log(items);
                        this.setState({
                            values: {
                                ...this.state.values,
                                ['freeTime']: items,
                                ['loadingFreeTime']: false
                            }
                        })
                        //setFreeTime(resp.data);
                    }
                    if (resp.status == 201) {
                        this.state.successMessage = this.props.t('bookingForm.donthaveavaibletime');
            
                        this.callAlert(this.state.successMessage, 'error');
                        this.setState({
                            values: {
                                ...this.state.values,
                                ['loadingFreeTime']: false
                            }
                        });
                       // alert("Чел, вихідний. Розслабся");
                    }
                }).catch((err) => {
                    console.log("error", err);
                    alert("error");
                    this.setState({
                        values: {
                            ...this.state.values,
                            ['loadingFreeTime']: false
                        }
                    });
                })
            };
            fetch();
        }
    };

    SetPhoneNum() {
        if (i18next.language === "en") {
            return "+1";
        }
        if (i18next.language === "et") {
            return "+372";
        }
        if (i18next.language === "uk") {
            return "+380";
        }
        if (i18next.language === "ru") {
            return "+7";
        }
        return '';
    };

    removeAlert = () => {
        clearTimeout(this.state.alertTimeout);

        this.setState({
            alertTimeout: setTimeout(function () {
                var element = document.querySelector('#alert');
                element.classList.remove('fadeIn');
                element.classList.add('fadeOut');

                setTimeout(function () {
                    element.remove()
                }, 900)
            }, this.state.delay
            )
        });
    };

    callAlert = (message, type) => {
        if (!document.querySelector('#alert')) {
            if (type === 'success') {
                this.state.alertClass = 'success';
            }

            if (type === 'error') {
                this.state.alertClass = 'danger';
            }

            if (type === 'warning') {
                this.state.alertClass = 'warning';
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector('#cf-1');

            element.insertAdjacentHTML('beforeend', alert);

            this.removeAlert();
        }
    };

    

    render() {
        const { t } = this.props;
        
        return (
            <form method="post" onSubmit={this.submitForm} id="cf-1" className="contact-form">
                <div style={{display:(this.state.values.smsBlockVisible?"none":"block")}} className="form-group-lg">
                    <div className="input-group gutter-width-sm">
                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-name-surename">{t('bookingForm.name')} *</label>
                            
                            <input name="name"
                                value={this.state.values.name}
                                onChange={this.handleInputChange}
                                type="text"
                                id="cf-1-name-surename"
                                placeholder={t('bookingForm.yourNameHere')} />
                        </span>

                        <span className="gutter-width" style={{display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"}}>
                            <label className="form-label-lg before after mb-0"  htmlFor="cf-1-services">{t('bookingForm.chooseAService')} *</label>

                            {/* <select name="selectedServiceId"
                                id="cf-1-services"
                                value={this.state.values.selectedServiceId}
                                placeholder={"Select service..."}
                                onChange={(e) => { this.handleInputChangeService(e.target.value); this.checkFreeTime(); }}
                                defaultValue={"1"}>
                                <option value={"1"} key='blankKey' hidden>Please select service...</option>
                                {this.state.values.services.map((service, i) => {
                                    // console.log(service);
                                    return (
                                        <option key={i} value={service.id}>
                                            {service.resources[service.resources.findIndex((res) => res.idLanguage === this.state.languages[this.state.languages.findIndex(
                                                (lang) => lang.shortName === i18next.language.substring(0,2))]?.id ?? 0)]?.text ?? ""}
                                        </option>)
                                })}
                            </select> */}
                    
                            <Select
                                name="selectedServiceId"
                                id="cf-1-services"
                                value={this.state.selectedServices}
                                styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,

                                      backgroundColor: 'noce',
                                      border: 'none',
                                      borderBottom: '1px solid #c8c6c3',
                                      borderColor: 'none'
                                    }),
                                  }}
                                // className="Select"
                                placeholder={ t`bookingForm.yourServiceHere`}
                                isMulti
                                options={this.state.values.services.map((service) => ({
                                    value: service.id,
                                    label: service.resources[service.resources.findIndex((res) =>
                                        res.idLanguage === this.state.languages[this.state.languages.findIndex(
                                            (lang) => lang.shortName === i18next.language.substring(0, 2)
                                        )]?.id ?? 0
                                    )]?.text ?? "",
                                }))}
                                onChange={async (selectedOptions) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                    await this.setState({ selectedServices: selectedOptions, values: { ...this.state.values, selectedServiceId: selectedValues } });
                                    this.checkFreeTime();
                                }}
                            />


                        </span>
                    </div>
                </div>

                <div style={{display:(this.state.values.smsBlockVisible?"none":"block")}} className="form-group form-group-lg">
                    <p className="input-group gutter-width-sm no-space">
                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-phone">{t('bookingForm.yourPhoneNumber')} *</label>

                            <PhoneInput name="phone"
                                value={this.state.values.phone}
                                onChange={this.handleInputChangePhone}
                                type="text"
                                id="cf-1-phone"
                                placeholder={t('bookingForm.yourPhoneHere')} />
                        </span>

                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-phone">{t('bookingForm.yourEmail')}</label>

                            <input name="email"
                                value={this.state.values.email}
                                onChange={this.handleInputChange}
                                type="text"
                                id="cf-1-email"
                                placeholder={t('bookingForm.yourEmailHere')} />
                        </span>
                    </p>

                    <p className="input-group gutter-width-sm no-space">

                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-date">{t('bookingForm.chooseAnAppointmentDate')}</label>
                            
                               { 
                            <input lang='en-GB' name="date"
                                value={this.state.values.date}
                                onChange={(e) => { this.handleInputChangeDate(e.target.value); console.log("e.target.value ", e.target.value); this.checkFreeTime(); }}
                                type="date"
                                id="cf-1-date"
                                min={moment().format("YYYY-MM-DD")}
                                max={moment().add(12, 'M').format("YYYY-MM-DD")}
                                placeholder="dd/mm/yyyy" />
                                
                               }

                        </span>

                        <span className="gutter-width">
                            <label className="form-label-lg before after mb-0" htmlFor="cf-1-time">{t('bookingForm.availableTime')}</label>
                            {/* <input name="date"
                            value={this.state.values.date}
                            onChange={this.handleInputChange}
                            type="text"
                            id="cf-1-time"
                            placeholder="" /> t('bookingForm.yourServiceHere')*/}
                            <BeatLoader cssOverride={{position:"absolute", marginTop:"10px"}} loading={this.state.values.loadingFreeTime} color="#567E7A" />
                            <select name="selectedTime"
                                id="cf-1-selectedtime"
                                value={this.state.values.selectedTime == undefined || this.state.values.selectedTime == '' ? null : this.state.values.selectedTime}
                                onChange={(e) => this.setState({ values: { ...this.state.values, ['selectedTime']: e.target.value } })}
                                defaultValue={"1"}>
                                <option value={"1"} key='blankKey' hidden>{t('bookingForm.yourAvailableTimeHere')}</option>
                                {this.state.values.freeTime.map((time, i) => (
                                    <option key={i} value={`${moment.utc(time.startTime).format("HH:mm")}!${moment.utc(time.endTime).format("HH:mm")}`}>
                                        {
                                            `${moment.utc(time.startTime).format("HH:mm")} - ${moment.utc(time.endTime).format("HH:mm")}`
                                        //`${time.startTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false })} - ${time.endTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false })}`
                                        }
                                    </option>

                                ))}
                            </select>
                        </span>

                    </p>

                </div>
                <div style={{display:(this.state.values.smsBlockVisible?"none":"block")}} className="form-group form-group-lg">
                    <label className="form-label-lg before after mb-0" htmlFor="cf-1-message">{t('bookingForm.comments')}</label>

                    <textarea name="message"
                        value={this.state.values.message}
                        onChange={this.handleInputChange}
                        id="cf-1-message"
                        placeholder={t('bookingForm.yourCommentsHere')}></textarea>
                </div>
                <div style={{display:(this.state.values.smsBlockVisible?"block":"none")}} className="form-group form-group-lg">
                    <label className="form-label-lg before after mb-0" htmlFor="cf-1-message">{t('bookingForm.enterSMSCode')}</label>

                    <input name="userSmsCode" value={this.state.values.userSmsCode} onChange={this.handleInputChange}
                                type="number" pattern="(\d{6})"
                                id="cf-1-smsCode"
                                placeholder={t('bookingForm.enterSMSCode')} />
                </div>
                <div style={{display:(this.state.values.smsBlockVisible?"none":"block")}} className="form-group form-group-lg mb-0">
                    <button type="submit" className="btn btn-outline-secondary">{t('bookingForm.bookNow')}</button>
                </div>
                <div style={{display:(this.state.values.smsBlockVisible?"block":"none")}} className="form-group form-group-lg mb-0">
                    <button type="button" onClick={this.checkSmsCode} className="btn btn-outline-secondary">{t('bookingForm.submitSMSCode')}</button>
                </div>
            </form>

                                        

        );
    }
}
export default withTranslation()(BookingForm);