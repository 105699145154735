import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalMenuLanguages = () => {

    const {i18n} = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }
    let defaultvalue = i18n.language;
    if(defaultvalue === "ru-RU"){
        defaultvalue = "ru";
    }
    return (
        <nav className="languages lang-position">
            <ul className="nav">
                <select className="lang-item current-lang" defaultValue={defaultvalue} onChange={e => changeLanguage(e.target.value)}>
                <option value="en">en</option>
                <option value="et">et</option>
                <option value="uk">uk</option>
                <option value="ru">ru</option>
                </select>
            </ul>
        </nav>
    );
};

export default ModalMenuLanguages;
