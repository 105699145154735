import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleServices from '../blocks/page-title/PageTitleServices';
import ServicesContent from "../blocks/services/ServicesContent";

const Services = () => {
    document.body.classList.add('archive');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Services | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">

                {/* <PageTitleServices /> */}

                <div id="page-content" className="spacer p-top-xl">

                    <div id="img" className="block">
                        <div className="img-no-wrap-1">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="assets/img/demo/12_img.jpg" alt="Hair Treatments" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServicesContent />
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Services;
