import React from 'react';
import scriptLoader from 'react-async-script-loader';
import GoogleMapsStyle from '../../data/google-maps/googleMapsStyle';

class Maps extends React.Component {
    constructor( props ) {
        super( props );
        this.mapRef = React.createRef();
        this.map = null;
    }

    componentWillReceiveProps( { isScriptLoaded, isScriptLoadSucceed } ) {
        if ( isScriptLoaded && !this.props.isScriptLoaded ) {
            // 189 Bedford Ave, Brooklyn, NY 11211, United States
            var address_latlng = new window.google.maps.LatLng( 59.442148142075595, 24.735275593034395 );
            // load finished
            if ( isScriptLoadSucceed ) {
                this.map = new window.google.maps.Map( this.mapRef.current, {
                    center: address_latlng,
                    zoom: 18,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: GoogleMapsStyle,
                } );

                var icon = {
                    url: require('../../assets/img/logo/logo_custom_dark.png'),
                    scaledSize: new window.google.maps.Size( 100, 47 )
                };

                this.map.setCenter( address_latlng );

                new window.google.maps.Marker( {
                    position: address_latlng,
                    map: this.map,
                    icon: icon
                } );

            } else this.props.onError();
        }
    }

    render() {
        return (
            <div id="google-maps" ref={ this.mapRef }></div>
        );
    }
}

export default scriptLoader( ['https://maps.googleapis.com/maps/api/js?key=AIzaSyCTP5YwmIqeWh2_-eH3djTCjaOEMksoHMk'] )( Maps );
