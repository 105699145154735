import React from 'react';
import { useTranslation } from 'react-i18next';

const ButtonSearch = () => {

    const {t} = useTranslation();

    return (
        <button type="submit" className="btn btn-sm btn-before-dot btn-link border-0 p-0 min-w-auto text-uppercase">{t("buttonSearch.search")}</button>
    );
};

export default ButtonSearch;
