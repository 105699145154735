import React, {useState, useEffect}from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Contacts = () => {

    const [aboutUs, setAboutUs] = useState([]);

    useEffect(() => {
		const fetch = async () => {
			await axios.get("api/AboutUs")
				.then((resp) => {
					if (resp.status == 200) {
						setAboutUs(resp.data);
					}
				}).catch((err) => {
					console.log("error", err);
					alert("error");
				})
		};
		fetch();
	}, []);

    const { t } = useTranslation();

    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="mailto:company@domain.com">{aboutUs.email}</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>{t('contacts.email')}</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>{aboutUs.address}</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>{t('contacts.address')}</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="tel:005053255350539">{aboutUs.phone}</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>{t('contacts.phone')}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
